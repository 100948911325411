.layout__wrapper {
  background-image: linear-gradient(0deg, hsl(215, 14%, 16%) 0%, hsl(215, 19%, 29%) 100%);
  border-radius: 3px;
  box-shadow: 0 12px 16px rgba(0, 0, 0, 0.25);
  margin: 3rem auto;
  max-width: 370px;
}

.cardClass {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}

.header {
  border-bottom: 1px solid gray;
  padding-bottom: 10px;
  margin-bottom: 5px;
}

.store-container {
  text-align: center;
  padding: 5px;
  background-color: rgb(0, 247, 255);
}

.items-container {
  text-align: center;
}

.item-container {
  padding: 5px;
  border-bottom: 1px solid grey;
}
.droppable-container {
  position: relative;
}

.droppable-border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px dashed lightgray; /* Dotted border for the droppable area */
  border-radius: 4px; /* Adjust as needed */
  pointer-events: none; /* Ensure the dotted border does not interfere with drag events */
}

.droppable-container.highlighted .droppable-border {
  background-color: lightblue; /* Highlighted background when dragging over */
}
